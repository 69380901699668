<template>
  <v-container
    fluid
    fill-height
    class="pa-0 align-center justify-center"
  >
    <form
      class="elevation-3 pa-10"
    >
      <div class="d-flex align-center justify-center pt-4 pb-4">
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="60px"
          width="auto"
        >
      </div>
      <v-text-field
        v-model="email"
        :label="$t('common|email')"
        disabled
        outlined
        dense
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      />
      <v-text-field
        v-model="password"
        dense
        :label="$t('password|enter_password')"
        required
        outlined
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="show ? 'text' : 'password'"
        name="input-10-1"
        :hint="$t('password|min_length')"
        counter
        @click:append="show = !show"
      />
      <v-text-field
        v-model="confirmPassword"
        dense
        :type="show ? 'text' : 'password'"
        :label="$t('password|confirm_password')"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        required
        outlined
        @click:append="show = !show"
      />
      <v-alert
        class="mt-2 mb-2"
        :value="!!error"
        type="error"
        border="bottom"
        transition="scale-transition"
      >
        {{ error }}
      </v-alert>
      <div class="d-flex justify-center align-center">
        <v-btn
          color="primary"
          class="mt-4"
          small
          min-width="200"
          @click="submit"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </div>
      <div
        class="d-flex justify-center align-center mt-5"
        style="font-size: 13px;"
      >
        <span>
          {{ $t('user|account_existing') }}
          <router-link to="/login">
            {{ $t('profile|log_in') }}
          </router-link>
        </span>
      </div>
      <v-overlay
        absolute
        :value="loading"
      >
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
      </v-overlay>
    </form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      show: false,
      email: null,
      password: '',
      confirmPassword: '',
      loading: false,
      error: null,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 7 || 'Min 7 characters'
      }
    }
  },
  computed: {},
  mounted () {
    if (this.$route.params && this.$route.params.email) {
      this.email = this.$route.params.email
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    async submit () {
      if (this.password !== this.confirmPassword) {
        this.error = this.$t('password|password_does_not_match')
      } else {
        this.loading = true
        try {
          const _id = this.$route.params._id
          const token = this.$route.params.token
          const payload = {
            password: this.password
          }
          const { data } = await this.$axios.post(`/confirmationClientAccount/${_id}/${token}`, payload)
          if (!data.success) {
            this.error = this.$t('warning|activation_not_successful')
            return
          } else {
            this.error = null
            this.$router.push({ path: '/login' })
            this.addToast({
              title: this.$t('warning|registration_successful'),
              color: 'white',
              snackbarColor: 'success'
            })
          }
        } catch (e) {
          console.error(e, 'ERROR')
          this.error = e
          this.addToast({
            title: this.$t('warning|registration_not_successful'),
            color: 'white',
            snackbarColor: 'error'
          })
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  form {
    position: relative;
    width: 100%;
    max-width: 500px;
    background-color: rgb(255, 255, 255);
  }
</style>
